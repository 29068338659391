import React, { useEffect } from 'react';

function Thatman() {
    useEffect(() => {
        document.title = "Piyush Chhabra | Portfolio"
    }, []);
  return (
      <div style={{width: '100%', height: '100vh'}}>
          <iframe
              src="https://portoo-nu.vercel.app/"
              style={{width: '100%', height: '100%', border: 'none'}}
              title="Piyush's Portfolio Page"
          ></iframe>
      </div>
  );
}

export default Thatman;